

















































































































































































































































































































































































































































































































































































































































































































import { FormBlock, HtmlModal, Scrollbar, SvgImage, TwButton } from '@/app/components';
import TreeView from '@/app/components/treeview/TreeView.vue';
import { useAxios, useFilters } from '@/app/composable';
import store from '@/app/store';
import { AccessLevel } from '@/modules/access-policy/constants/access-levels.constants';
import { GeneralPolicy } from '@/modules/access-policy/models';
import { ModelSourceOptions } from '@/modules/asset/constants';
import {
    ArrowCircleUpIcon,
    CheckCircleIcon,
    CheckIcon,
    DocumentIcon,
    ExclamationCircleIcon,
    RefreshIcon,
    XCircleIcon,
    XIcon,
} from '@vue-hero-icons/outline';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import Papa from 'papaparse';
import * as R from 'ramda';
import Draggable from 'vuedraggable';
import { AccessPolicy } from '../../access-policy/components';
import WorkflowAPI from '../../workflow-designer/api/workflow';
import { AssetsAPI } from '../api';
import { ModelAlgorithmSelector } from '../components';
import ModelFeature from '../components/ModelFeature.vue';
import { useModelRegistration } from '../composable/model-registration';

export default defineComponent({
    name: 'RegisterModel',
    metaInfo: {
        title: 'Model Registration',
    },
    components: {
        FormBlock,
        AccessPolicy,
        TreeView,
        Draggable,
        ModelFeature,
        HtmlModal,
        SvgImage,
        TwButton,
        XIcon,
        CheckIcon,
        DocumentIcon,
        CheckCircleIcon,
        XCircleIcon,
        RefreshIcon,
        ExclamationCircleIcon,
        ArrowCircleUpIcon,
        ModelAlgorithmSelector,
        Scrollbar,
    },
    setup(props, { root, emit }) {
        const { formatBytes } = useFilters();
        const metadata = {
            general: true,
            extent: true,
            licensing: true,
            model: true,
        };
        const axiosRunner = useAxios(true);
        const asset = ref<any>();
        const models = ref([]);
        const sampleRef = ref<any>(null);
        const encodedFeature = ref<string>('');
        const additionalData = ref<any>({});
        const modelName = ref<string>('');

        const {
            accessLevel,
            copyrightOwner,
            customLicense,
            getLicensingSchema,
            getGeneralSchema,
            initAsset,
            accessLevelOptions,
            modelTypeOptions,
            modelPurposeOptions,
            modelLibraryOptions,
            listenToWorkflow,
            showModelValidationModal,
            modelValidationStatus,
            canCloseModal,
            modalImage,
            modalTitle,
            modalDescription,
            librarySelected,
            modelStructure,
            checkLicense,
            modelValidationMessage,
        } = useModelRegistration(asset);

        asset.value = initAsset(metadata);

        const modelFile = ref<any>();
        const sampleFile = ref<any>();
        const sample = ref<any>();

        const validForms = ref<any>({
            model: false,
            modelValidation: false,
            general: false,
            generalMetadata: false,
            accessLevelCopyrightOwner: false,
            licensing: false,
        });

        const user = computed(() => store.state.auth.user);

        const accessPolicies = ref<any>({ generalPolicy: GeneralPolicy.DENY_ALL, policies: [] });

        const saveClicked = ref<boolean>(false);

        const contentRef = ref<HTMLElement>();

        const featuresRef = ref<string[]>([]);
        const encodedFeaturesRef = ref<string[]>([]);

        const selectedFeatures = ref<string[]>([]);

        const clearFilesAndFeatures = () => {
            featuresRef.value = [];
            selectedFeatures.value = [];
            encodedFeaturesRef.value = [];
            sampleFile.value = null;
            sample.value = null;
            modelFile.value = null;
            additionalData.value = {};
            modelValidationStatus.value = '';
        };

        const checkFeature = (index: number) => {
            const checkedElement = featuresRef.value[index];
            featuresRef.value.splice(index, 1);
            const firstUncheckedElement = featuresRef.value.find(
                (feature) => !selectedFeatures.value.includes(feature),
            );
            if (!firstUncheckedElement) featuresRef.value.push(checkedElement);
            else {
                const idx = featuresRef.value.indexOf(firstUncheckedElement);
                featuresRef.value.splice(idx, 0, checkedElement);
            }
            modelValidationStatus.value = '';
        };

        const uncheckFeature = (index: number) => {
            const uncheckedELement = featuresRef.value[index];
            featuresRef.value.splice(index, 1);
            featuresRef.value.push(uncheckedELement);
            modelValidationStatus.value = '';
        };

        const featureMoved = (event: any) => {
            const { oldIndex, newIndex } = event;
            if (oldIndex < newIndex) {
                featuresRef.value = R.move(oldIndex, newIndex - 1, featuresRef.value);
            } else if (oldIndex > newIndex) {
                featuresRef.value = R.move(oldIndex, newIndex + 1, featuresRef.value);
            }
            modelValidationStatus.value = '';
        };

        const encodedFeatureMoved = (event: any) => {
            const { oldIndex, newIndex } = event;
            if (oldIndex < newIndex) {
                encodedFeaturesRef.value = R.move(oldIndex, newIndex - 1, encodedFeaturesRef.value);
            } else if (oldIndex > newIndex) {
                encodedFeaturesRef.value = R.move(oldIndex, newIndex + 1, encodedFeaturesRef.value);
            }
            modelValidationStatus.value = '';
        };

        const deleteFeature = (index: number) => {
            encodedFeaturesRef.value.splice(index, 1);
            modelValidationStatus.value = '';
        };

        const addFeature = () => {
            if (!encodedFeaturesRef.value.includes(encodedFeature.value) && encodedFeature.value !== '') {
                encodedFeaturesRef.value.push(encodedFeature.value);
                encodedFeature.value = '';
                modelValidationStatus.value = '';
            }
        };

        const clearFeature = () => {
            encodedFeature.value = '';
        };

        const hasEncodedFeatures = computed(() => {
            return (
                asset.value?.metadata?.model?.type === 'transformer' &&
                asset.value?.metadata?.model?.library === 'sklearn'
            );
        });

        const hasSampleAndFeatures = computed(() => asset.value?.metadata?.model?.purpose !== 'timeseries forecasting');

        const isVarModel = computed(() => asset.value?.metadata?.model?.purpose === 'timeseries forecasting var');

        const hasCompletedModelDetails = computed(() => {
            return (
                asset.value?.metadata?.model?.library !== '' &&
                asset.value?.metadata?.model?.type !== '' &&
                asset.value?.metadata?.model?.purpose !== '' &&
                asset.value?.metadata?.model?.algorithm !== ''
            );
        });

        const canExecuteTestRun = computed(() => {
            return (
                asset.value.name &&
                hasCompletedModelDetails.value &&
                (!hasSampleAndFeatures.value || (featuresRef.value.length > 0 && sampleFile.value)) &&
                (!hasEncodedFeatures.value || encodedFeaturesRef.value.length > 0) &&
                (!isVarModel.value || (additionalData.value.lag && additionalData.value.steps)) &&
                modelFile.value
            );
        });

        const modelType = computed(() => {
            if (asset.value?.metadata?.model?.library && asset.value?.metadata?.model?.type) {
                if (asset.value.metadata.model.library === 'xgboost' && asset.value.metadata.model.type === 'model') {
                    return 'sklearn-model';
                }
                if (
                    asset.value.metadata.model.library === 'statsmodel' &&
                    asset.value.metadata.model.type === 'model'
                ) {
                    return 'statsmodel';
                }
                if (asset.value.metadata.model.library === 'pmdarima' && asset.value.metadata.model.type === 'model') {
                    return 'pmdarima';
                }

                return `${asset.value.metadata.model.library}-${asset.value.metadata.model.type}`;
            }
            return '';
        });

        const validateModel = async () => {
            const modelMetadata: any = {
                type: modelType.value,
                purpose: asset.value.metadata.model.purpose,
                name: asset.value.metadata.model.algorithm,
                displayName: asset.value.name,
                additionalData: additionalData.value,
                source: ModelSourceOptions.uploaded,
            };

            if (hasEncodedFeatures.value) {
                modelMetadata.features_encoded = encodedFeaturesRef.value;
            }

            if (hasSampleAndFeatures.value) {
                modelMetadata.feature_order = featuresRef.value.filter((feature: string) =>
                    selectedFeatures.value.includes(feature),
                );
            }

            const frameworkVersion = store.state.executionVersions.analyticsExecutionVersion;

            showModelValidationModal.value = true;
            canCloseModal.value = false;

            modelValidationStatus.value = 'uploading';
            modelName.value = `uploaded-model-${Date.now()}`;
            axiosRunner
                .exec(
                    WorkflowAPI.validateModel(
                        modelName.value,
                        modelMetadata,
                        frameworkVersion as string,
                        sampleFile.value,
                        modelFile.value,
                    ),
                )
                .then((response: any) => {
                    if (response.data.workflowId && response.data.executionId) {
                        modelValidationStatus.value = 'running';
                        listenToWorkflow(response.data.workflowId, response.data.executionId);
                    } else {
                        modelValidationStatus.value = 'invalid';
                        canCloseModal.value = true;
                    }
                })
                .catch(() => {
                    modelValidationStatus.value = 'invalid';
                    canCloseModal.value = true;
                });
        };

        const cancel = () => {
            root.$router.push({ name: 'assets', query: store.state.queryParams.assets });
        };
        // TODO: Hide this for now
        // const getAccessPoliciesJSON = () => {
        //     const json = [];

        //     if (accessLevel.value === AccessLevel.OrganisationLevel) {
        //         const policy: ExceptionPolicy = new ExceptionPolicy(true, [
        //             new IndividualCondition(Field.ORGANISATION_ID, Operant.EQUALS, [
        //                 new ConditionValue(user.value.organisationId, Field.ORGANISATION_ID.key),
        //             ]),
        //         ]);
        //         json.push(policy.toJSON());
        //     } else if (accessLevel.value === AccessLevel.SelectiveSharing) {
        //         const policy: ExceptionPolicy = new ExceptionPolicy(accessPolicies.value.generalPolicy.allow, [
        //             new IndividualCondition(Field.ORGANISATION_ID, Operant.EQUALS, [
        //                 new ConditionValue(user.value.organisationId, Field.ORGANISATION_ID.key),
        //             ]),
        //         ]);
        //         json.push(policy.toJSON());
        //         accessPolicies.value.policies.forEach((exceptionPolicy: ExceptionPolicy) => {
        //             json.push(exceptionPolicy.toJSON());
        //         });
        //     } else {
        //         accessPolicies.value.policies.forEach((policy: ExceptionPolicy) => {
        //             json.push(policy.toJSON());
        //         });
        //     }

        //     return json;
        // };

        const saveChanges = async () => {
            // licensing metadata
            if (metadata.licensing && asset.value.metadata.license) {
                asset.value.metadata.license.copyrightOwner = copyrightOwner.value;
            }
            asset.value.accessLevel = accessLevel.value;
            // asset.value.policies = getAccessPoliciesJSON();

            if (hasEncodedFeatures.value) {
                asset.value.metadata.model.encodedFeatures = encodedFeaturesRef.value;
            }

            if (hasSampleAndFeatures.value) {
                asset.value.metadata.model.featureOrder = featuresRef.value.filter((feature: string) =>
                    selectedFeatures.value.includes(feature),
                );
            }

            asset.value.metadata.model.name = modelName.value;
            asset.value.metadata.model.source = ModelSourceOptions.uploaded;

            const payload = R.clone(asset.value);

            const policies = accessPolicies.value.policies?.add ? accessPolicies.value.policies?.add : [];

            axiosRunner
                .exec(AssetsAPI.createModelAsset({ ...payload, policies } as any))
                .then(() => {
                    (root as any).$toastr.s('Model successfully registered!', 'Success');
                    root.$router.push({ name: 'assets', query: store.state.queryParams.assets });
                })
                .catch(() => {
                    (root as any).$toastr.e('Model could not be registered!', 'Error');
                });
        };

        const save = computed(() => {
            if (
                !validForms.value.general ||
                (metadata.general && !validForms.value.generalMetadata) ||
                !validForms.value.model ||
                !validForms.value.modelValidation ||
                (metadata.licensing && (!validForms.value.accessLevelCopyrightOwner || !validForms.value.licensing))
            ) {
                return false;
            }
            return true;
        });

        const submitForms = () => {
            saveClicked.value = true;
            validForms.value = {
                general: false,
                generalMetadata: false,
                model: false,
                modelValidation: false,
                accessLevelCopyrightOwner: false,
                licensing: false,
            };
            (root as any).$formulate.submit('general');
            if (metadata.general) (root as any).$formulate.submit('generalMetadata');
            (root as any).$formulate.submit('model');

            validForms.value.modelValidation = modelValidationStatus.value === 'completed';

            if (metadata.licensing) {
                validForms.value.accessLevelCopyrightOwner = false;
                (root as any).$formulate.submit('accessLevelCopyrightOwner');
                if (
                    !accessLevel.value ||
                    accessLevel.value === AccessLevel.Private ||
                    accessLevel.value === AccessLevel.OrganisationLevel
                ) {
                    validForms.value.licensing = true;
                } else {
                    validForms.value.licensing = false;
                    (root as any).$formulate.submit('licensing');
                }
            }
        };

        const formSubmitted = (name: string) => {
            if (saveClicked.value) {
                validForms.value[name] = true;
                if (save.value) {
                    saveChanges();
                } else if (contentRef.value) {
                    contentRef.value.scrollIntoView({ behavior: 'smooth' });
                }
            }
        };

        const duplicateHeaders = ref<boolean>(false);
        const invalidFormat = ref<boolean>(false);

        const checkInvalidCSV = async (file: any) => {
            if (!file) return;
            const data = await file.text();
            Papa.parse(data, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    // check if there are any duplicate headers
                    const headers = results.meta.fields;
                    if (headers) {
                        const uniqueHeaders = new Set(headers); // keep only the unique headers
                        duplicateHeaders.value = headers.length !== uniqueHeaders.size;
                    }

                    invalidFormat.value = !!results.errors.length; // check if there are any errors, thus invalid file format
                },
            });
        };

        const errorAlert: any = ref({
            title: null,
            body: {
                necessary: null,
                invalid: null,
            },
        });

        const parseCSV = (file: any) => {
            Papa.parse(file, {
                header: true,
                preview: 50,
                dynamicTyping: true,
                transform: (value) => value.trim(),
                complete: (results) => {
                    sampleFile.value = file;
                    sample.value = results.data;
                    if (hasSampleAndFeatures.value) {
                        featuresRef.value = results.meta.fields as string[];
                        selectedFeatures.value = R.clone(featuresRef.value);
                    }
                },
            });
        };

        const clearErrorAlert = () => {
            errorAlert.value = {
                title: null,
                body: {
                    necessary: null,
                    invalid: null,
                },
            };
        };

        const sampleUploaded = async (event: any) => {
            const file = event.target.files[0];

            modelValidationStatus.value = '';
            await checkInvalidCSV(file);
            if (invalidFormat.value || duplicateHeaders.value) {
                (root as any).$toastr.e(
                    invalidFormat.value ? 'Invalid CSV format!' : 'Duplicate columns have been detected in the file!',
                    'Error',
                );

                errorAlert.value.title = duplicateHeaders.value
                    ? `Duplicate columns have been detected in the Sample file "${file.name}"`
                    : null;
            } else {
                await parseCSV(file);
                clearErrorAlert();
            }
        };

        const modelUploaded = async (event: any) => {
            const file = event.target.files[0];
            modelFile.value = file;
            modelValidationStatus.value = '';
        };

        const validationStatusText = computed(() => {
            switch (modelValidationStatus.value) {
                case '':
                    return 'Not validated';
                case 'completed':
                    return 'Validated';
                case 'failed':
                case 'invalid':
                    return 'Validation Failed';
                default:
                    return 'Validation Pending';
            }
        });

        const resetLicenseAndPricing = (level: any) => {
            if (level) {
                if (level === AccessLevel.Private) {
                    asset.value.metadata.license.license = null;
                    asset.value.metadata.license.copyrightOwner = null;
                    asset.value.metadata.license.link = null;
                } else if (
                    level === AccessLevel.SelectiveSharing &&
                    asset.value.metadata.license.license !== 'Custom'
                ) {
                    asset.value.metadata.license.license = 'Custom';
                    checkLicense({ id: 'Custom', label: 'Custom' });
                }
            }
        };

        watch(
            () => asset.value && asset.value.metadata.license && accessLevel.value,
            (level) => {
                resetLicenseAndPricing(level);
            },
        );

        return {
            contentRef,
            accessLevel,
            copyrightOwner,
            customLicense,
            cancel,
            asset,
            metadata,
            getLicensingSchema,
            accessLevelOptions,
            submitForms,
            formSubmitted,
            accessPolicies,
            user,
            validForms,
            save,
            saveClicked,
            // modelSourceOptions,
            modelTypeOptions,
            modelPurposeOptions,
            modelLibraryOptions,
            modelUploaded,
            sampleUploaded,
            featureMoved,
            encodedFeatureMoved,
            featuresRef,
            encodedFeaturesRef,
            emit,
            models,
            modelStructure,
            librarySelected,
            modelFile,
            sampleRef,
            sampleFile,
            sample,
            formatBytes,
            validateModel,
            showModelValidationModal,
            modelValidationStatus,
            canCloseModal,
            modalImage,
            modalTitle,
            modalDescription,
            canExecuteTestRun,
            selectedFeatures,
            checkFeature,
            uncheckFeature,
            validationStatusText,
            hasSampleAndFeatures,
            hasEncodedFeatures,
            deleteFeature,
            addFeature,
            encodedFeature,
            clearFeature,
            clearFilesAndFeatures,
            hasCompletedModelDetails,
            isVarModel,
            additionalData,
            getGeneralSchema,
            AccessLevel,
            modelValidationMessage,
        };
    },
});
