









































































































































































































































































































































































































































































































































































































































































































































































































import {
    AlertBanner,
    ConfirmModal,
    DataModelTree,
    DescriptionListCard,
    DescriptionListItem,
    JsonEditor,
    Scrollbar,
    Tabs,
} from '@/app/components';
import { useAxios, useQueryParams } from '@/app/composable';
import { useBlob } from '@/app/composable/blob';
import { useFilters } from '@/app/composable/filters';
import { useRouter } from '@/app/composable/router';
import { Model } from '@/app/interfaces';
import store from '@/app/store';
import { S } from '@/app/utilities';
import { AccessLevel, AccessLevelsOptions } from '@/modules/access-policy/constants/access-levels.constants';
import {
    AccrualPeriodicityInterval,
    AccrualPeriodicityUnits,
    ModelSource,
    ModelSourceOptions,
} from '@/modules/asset/constants';
import { ModelsAPI } from '@/modules/data-model/api';
import {
    ArchiveIcon,
    ChevronLeftIcon,
    CloudDownloadIcon,
    PencilAltIcon,
    RefreshIcon,
    ReplyIcon,
    TrashIcon,
} from '@vue-hero-icons/outline';
import { DatabaseIcon } from '@vue-hero-icons/solid';
import { Ref, computed, defineComponent, onBeforeUnmount, ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { OrbitSpinner, SelfBuildingSquareSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { AccessPolicy } from '../../access-policy/components';
import { AssetsAPI } from '../api';
import ModelFeature from '../components/ModelFeature.vue';
import QualityTab from '../components/QualityTab.vue';
import { useAsset } from '../composable/asset';
import { useAssetMetadata } from '../composable/asset-metadata';
import { useAssetRetrieval } from '../composable/asset-retrieval';
import { useAssetStatus } from '../composable/asset-status';
import { AssetType, AssetTypeId, StatusCode } from '../constants';
import { Asset } from '../types';

dayjs.extend(minMax);

export default defineComponent({
    name: 'ViewAsset',
    metaInfo() {
        return { title: (this as any).asset ? (this as any).asset.name : 'View Asset' };
    },
    props: {
        id: {
            type: [Number, String],
            required: false,
        },
    },
    components: {
        OrbitSpinner,
        DataModelTree,
        Tabs,
        AccessPolicy,
        Scrollbar,
        DatabaseIcon,
        ModelFeature,
        DescriptionListCard,
        DescriptionListItem,
        QualityTab,
        TrashIcon,
        PencilAltIcon,
        ArchiveIcon,
        ReplyIcon,
        ChevronLeftIcon,
        ConfirmModal,
        JsonEditor,
        CloudDownloadIcon,
        SelfBuildingSquareSpinner,
        RefreshIcon,
        AlertBanner,
    },
    setup(props, { root }) {
        const SAMPLE_FIELDS_LIMIT = 500;
        const asset: Ref<Asset | undefined> = ref<Asset | undefined>();
        const user = computed(() => store.state.auth.user);
        const { assetTypeName } = useAsset();
        const {
            isRetrievalDisabled,
            retrievalTooltip,
            accessibilityKey,
            belongsToUserOrOrganisation,
        } = useAssetRetrieval(asset, user);
        const router = useRouter();

        const { get, set } = useQueryParams(root, router, 'assets:view');

        const { formatDecimals, fromNow, formatDateTime } = useFilters();
        const tabs = ref<{ title: string; key: string }[]>([]);

        const id: Ref<number | undefined> = computed(() => (props.id ? parseInt(`${props.id}`, 10) : undefined));
        const { exec, error } = useAxios(true);
        const { locationOptions, createTreeStructure, getDomain } = useAssetMetadata();
        const metadata = { general: true, distribution: true, extent: true, licensing: true };
        const flatModel = ref<any>(null);
        const dataStructure = ref<any>(null);
        const inputAssets = ref<any>([]);
        const processedSample = ref<any>(null);
        const rejectedItems = ref<number>(0);
        const loading = ref<boolean>(false);
        const loadingProcessedSample = ref<boolean>(false);
        const loadingFlatModel = ref<boolean>(false);

        const updatedDate = computed(() =>
            asset.value?.modifiedAt
                ? dayjs.max([dayjs(asset.value.modifiedAt), dayjs(asset.value.updatedAt)]).toString()
                : asset.value?.updatedAt,
        );

        const isDataOwner = computed(() => user.value.id === asset.value?.createdBy.id);
        const status: Ref<StatusCode | undefined> = computed((): StatusCode | undefined => asset.value?.status);
        const { label: assetStatusLabel, colour: assetStatusClass } = useAssetStatus(status);

        const assetType = computed(() => {
            if (asset.value && asset.value.assetTypeId) return assetTypeName(asset.value.assetTypeId);
            return AssetType.Dataset;
        });
        const showDeleteModal = ref<boolean>(false);
        const affectedPipelinesAndQueries = ref<{ pipelines: number; queries: number }>({ pipelines: 0, queries: 0 });

        const refreshProvenanceIds = computed(() => store.state.notificationEngine.refresh.provenanceIds);

        const refresh = computed(
            () =>
                refreshProvenanceIds.value.length &&
                asset.value?.provenanceAssetWorkflowIds &&
                asset.value.provenanceAssetWorkflowIds.some((workflowId: string) =>
                    refreshProvenanceIds.value.includes(workflowId),
                ),
        );

        onBeforeUnmount(() => {
            if (refresh.value) store.dispatch.notificationEngine.clearRefreshProvenanceIds();
        });

        const { download } = useBlob();

        const activeTab: Ref<string | undefined> = computed({
            get: () => get('tab', false, tabs.value.length > 0 ? tabs.value[0].key : undefined),
            set: (newTab: string) => set('tab', newTab, tabs.value.length > 0 ? tabs.value[0].key : undefined),
        });

        const tabIndex: Ref<number> = computed({
            get: () => tabs.value.findIndex((t: { key: string; title: string }) => t.key === activeTab.value),
            set: (newIndex: number) => (activeTab.value = tabs.value[newIndex].key),
        });

        const createTabs = () => {
            tabs.value = [{ key: 'overview', title: 'Overview' }];

            if (
                [AssetType.Dataset, AssetType.Result].includes(assetType.value) &&
                asset.value?.status === StatusCode.Available &&
                asset.value?.metadata?.supportMetrics !== false
            )
                tabs.value.push({ title: 'Quality', key: 'quality' });

            tabs.value.push({ title: 'Sharing Details', key: 'sharingDetails' });
            if (asset.value?.metadata?.model && asset.value?.metadata.model.featureOrder)
                tabs.value.push({ title: 'Model Structure', key: 'modelStructure' });
            if (asset.value?.structure?.primaryConcept)
                tabs.value.push({ title: 'Data Structure', key: 'dataStructure' });
        };

        const loadDataStructure = async () => {
            const primaryConcept = asset.value?.structure?.primaryConcept;
            const schema = asset.value?.structure?.schema;
            if (primaryConcept && schema && schema.length > 0)
                dataStructure.value = createTreeStructure(schema, primaryConcept, flatModel.value);
        };

        const spatialCoverageCountries = computed(() => {
            const array: any = [];
            if (asset.value?.metadata?.extent?.spatialCoverage?.values && locationOptions.value)
                asset.value.metadata.extent.spatialCoverage.values.forEach((value: string) => {
                    locationOptions.value.forEach((global: any) => {
                        if (global.id === value) array.push(global.label);
                        else
                            global.children.forEach((continent: any) => {
                                if (continent.id === value) array.push(continent.label);
                                else
                                    continent.children.forEach((country: any) => {
                                        if (country.id === value) array.push(country.label);
                                    });
                            });
                    });
                });
            return array;
        });

        const temporalCoverage = computed(() => {
            if (asset.value?.metadata?.extent?.temporalCoverage?.unit) {
                switch (asset.value.metadata.extent.temporalCoverage.unit) {
                    case 'Time Period':
                        return `${dayjs(asset.value.metadata.extent.temporalCoverage.min).format(
                            'MMM D, YYYY',
                        )}~${dayjs(asset.value.metadata.extent.temporalCoverage.max).format('MMM D, YYYY')}`;
                    case 'Single Date':
                        return dayjs(asset.value.metadata.extent.temporalCoverage.min).format('MMM D, YYYY');
                    case 'Calculated based on data':
                        if (
                            asset.value.metadata.extent.temporalCoverage.min &&
                            asset.value.metadata.extent.temporalCoverage.max &&
                            asset.value.metadata.extent.temporalCoverage.field
                        ) {
                            const fieldType = asset.value?.structure?.temporalFields.find(
                                (temporalField: any) =>
                                    temporalField.uid === asset.value?.metadata?.extent.temporalCoverage.field.uid &&
                                    temporalField.name === asset.value?.metadata?.extent.temporalCoverage.field.name,
                            ).type;
                            let format = '';
                            let { min, max } = asset.value.metadata.extent.temporalCoverage;
                            switch (fieldType) {
                                case 'date':
                                    format = 'MMM D, YYYY';
                                    return `${dayjs(min).format(format)} ~ ${dayjs(max).format(format)}`;
                                case 'time':
                                    format = 'HH:mm:ss UTC';
                                    min = `1970-01-01T${min}`;
                                    max = `1970-01-01T${max}`;
                                    return `${dayjs(min).utc().format(format)} ~ ${dayjs(max).utc().format(format)}`;
                                case 'datetime':
                                    format = 'MMM D, YYYY HH:mm:ss UTC';
                                    return `${dayjs(min).utc().format(format)} ~ ${dayjs(max).utc().format(format)}`;
                                default:
                                    return 'N/A';
                            }
                        }
                        return 'N/A';
                    case 'Not applicable':
                        return asset.value.metadata.extent.temporalCoverage.unit;
                    default:
                        return `${asset.value.metadata.extent.temporalCoverage.value} ${asset.value.metadata.extent.temporalCoverage.unit}`;
                }
            }
            return null;
        });

        const hiddenSpatialValuesCount = computed(() => {
            if (asset.value?.metadata?.extent?.spatialCoverage?.unit === 'Calculated based on data') {
                const { values, count } = asset.value.metadata.extent.spatialCoverage;
                return count - values?.length;
            }
            return 0;
        });

        const spatialCoverage = computed(() => {
            if (asset.value?.metadata?.extent?.spatialCoverage?.unit) {
                const { unit, coordinates, values, value } = asset.value.metadata.extent.spatialCoverage;
                switch (unit) {
                    case 'Specific Continent/Countries':
                        return spatialCoverageCountries.value.join(', ');
                    case 'Exact Location':
                        return `Latitude: ${coordinates.lat}, Longitude: ${coordinates.lon}`;
                    case 'Calculated based on data':
                        if (values?.length > 0) return values.join(', ');
                        return 'N/A';
                    case 'Not applicable':
                        return unit;
                    default:
                        return value;
                }
            }
            return null;
        });

        const temporalCoverageDescription = computed(() => {
            if (asset.value?.metadata?.extent?.temporalCoverage?.unit) {
                switch (asset.value.metadata.extent.temporalCoverage.unit) {
                    case null:
                    case 'Not applicable':
                        return 'The time period during which the data were collected or the time period the data are referring to.';
                    case 'Time Period':
                    case 'Single Date':
                        return 'A named period, date, or date range that the asset covers.';
                    case 'Years':
                        return 'The number of indicative years to which the asset refers.';
                    case 'Months':
                        return 'The number of indicative months to which the asset refers.';
                    case 'Days':
                        return 'The number of indicative days to which the asset refers.';
                    case 'Hours':
                        return 'The number of indicative hours to which the asset refers.';
                    case 'Calculated based on data':
                        return 'The period that the asset covers is extracted from a specific concept/field in the data.';
                    default:
                        return '';
                }
            }
            return '';
        });

        const spatialCoverageDescription = computed(() => {
            if (asset.value?.metadata?.extent?.spatialCoverage?.unit) {
                const { unit } = asset.value.metadata.extent.spatialCoverage;
                switch (unit) {
                    case null:
                    case 'Not applicable':
                        return 'The location/area the data refer to or were collected from; either defined directly (e.g. geographical area) or indirectly (i.e. place of interest or an activity that is the subject of the collection).';
                    case 'Specific Continent/Countries':
                        return 'The spatial coverage of the asset, in terms of countries to which the asset refers.';
                    case 'Exact Location':
                        return 'The spatial coverage of the asset, in terms of exact location(s) to which the asset refers.';
                    case 'Calculated based on data':
                        return 'The spatial coverage of the asset as extracted from a specific concept/field in the data.';
                    default:
                        return `The spatial coverage of the asset, in terms of ${unit
                            .substring(8, unit.length)
                            .toLowerCase()}(s) to which the asset refers.`;
                }
            }
            return '';
        });

        const fetchAsset = (assetId: number) => {
            loading.value = true;
            exec(AssetsAPI.getAsset(assetId))
                .then(async (res: any) => {
                    asset.value = res.data;
                    createTabs();
                    loading.value = false;
                })
                .catch((e) => {
                    if (e.response.status !== 403) throw e; // if the error is not for forbidden access, then send to sentry
                    (root as any).$toastr.e('You do not have access to view the specific asset.', 'Access Forbidden!');
                    backToAssets();
                });
        };

        const modelDisplayName = computed(() => {
            if (asset.value && asset.value?.metadata?.model) {
                if (asset.value.metadata.model.source === ModelSourceOptions.uploaded) return asset.value.name;
                return asset.value.metadata.model.name;
            }
            return '';
        });

        const customError: Ref<{ title: string; message: string } | undefined> = computed(() => {
            if (!error.value) return undefined;
            // case of no metrics found is handled differently
            if (
                error.value.response.status === 404 &&
                error.value.request?.responseURL &&
                error.value.request.responseURL.match(/\/metrics$/)
            )
                return undefined;
            return {
                title: error.value.response.status === 403 ? 'Access Forbidden!' : 'An error has occurred',
                message:
                    error.value.response.status === 403
                        ? 'You do not have access to view the specific asset.'
                        : error.value.message,
            };
        });

        const navigationPayload = (provenanceAssetWorkflow: { id: string; type: string }) => {
            if (!asset.value) return null;
            if (provenanceAssetWorkflow.type === 'data-checkin') {
                const query: any = { pipeline: provenanceAssetWorkflow.id };
                if (asset.value.createdBy.id !== user.value.id) query.tab = 'shared';
                return { name: 'data-checkin-jobs', query };
            }
            return {
                name: 'workflow-designer:edit',
                params: {
                    id: `${provenanceAssetWorkflow.id}`,
                    backTo: 'assets:view',
                    backToId: `${asset.value.id}`,
                },
            };
        };

        const isModel = computed(() => asset.value?.assetTypeId === AssetTypeId.Model);
        const isUploadedModel = computed(
            () => isModel.value && asset.value?.metadata?.model.source === ModelSourceOptions.uploaded,
        );

        const confirmDelete = () => {
            if (asset.value)
                exec(AssetsAPI.getAffectedPipelinesQueries(asset.value.id))
                    .then((res: any) => {
                        affectedPipelinesAndQueries.value = res.data;
                        showDeleteModal.value = true;
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Deleting Asset failed', 'Error');
                    });
        };

        const confirmationDeleteMessage = computed(() => {
            let message =
                'Deleting this asset will permanently remove all its data yet its metadata will remain for provenance reasons.';
            if (affectedPipelinesAndQueries.value.pipelines > 0 || affectedPipelinesAndQueries.value.queries > 0) {
                message = message + ' There ';
                message =
                    message +
                    `${
                        affectedPipelinesAndQueries.value.pipelines + affectedPipelinesAndQueries.value.queries > 1
                            ? 'are '
                            : 'is '
                    }`;
                if (affectedPipelinesAndQueries.value.pipelines > 0) {
                    message =
                        message +
                        `<span class= 'font-bold'>${affectedPipelinesAndQueries.value.pipelines}</span> Analytics Pipeline(s) `;
                }
                if (affectedPipelinesAndQueries.value.pipelines > 0 && affectedPipelinesAndQueries.value.queries > 0) {
                    message = message + 'and ';
                }
                if (affectedPipelinesAndQueries.value.queries > 0) {
                    message =
                        message +
                        `<span class= 'font-bold'>${affectedPipelinesAndQueries.value.queries}</span> Retrieval Querie(s) `;
                }
                message =
                    message +
                    `that ${
                        affectedPipelinesAndQueries.value.pipelines > 1 || affectedPipelinesAndQueries.value.queries > 1
                            ? 'are'
                            : 'is'
                    } currently using this asset and will be affected.`;
            }
            message =
                message +
                ' Please note that this action cannot be undone! Are you sure you still want to delete this asset?';
            return message;
        });

        const showActions = computed(
            () =>
                asset.value && asset.value.createdBy.id === user.value.id && asset.value.status !== StatusCode.Deleted,
        );

        const backToAssets = () => {
            root.$router.push({ name: 'assets', query: store.state.queryParams.assets });
        };

        const editAsset = () => {
            root.$router.push({
                name: asset.value?.assetTypeId === AssetTypeId.Model ? 'assets:model:edit' : 'assets:edit',
                params: { id: `${id.value}`, backTo: 'assets:view' },
            });
        };

        const deleteAsset = () => {
            if (id.value)
                exec(AssetsAPI.deleteAsset(id.value))
                    .then((res: any) => {
                        asset.value = res.data;
                        (root as any).$toastr.s('Asset deleted successfuly', 'Success');
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Deleting Asset failed', 'Error');
                    });
        };

        const modalConfirmed = () => {
            showDeleteModal.value = false;
            deleteAsset();
        };

        const archive = () => {
            if (id.value)
                exec(AssetsAPI.archiveAsset(id.value))
                    .then((res: any) => {
                        asset.value = res.data;
                        (root as any).$toastr.s('Model Asset archived successfuly', 'Success');
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Archiving Model Asset failed', 'Error');
                    });
        };

        const restore = () => {
            if (id.value)
                exec(AssetsAPI.restoreArchivedAsset(id.value))
                    .then((res: any) => {
                        asset.value = res.data;
                        (root as any).$toastr.s('Model Asset restored successfuly', 'Success');
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Restoring Model Asset failed', 'Error');
                    });
        };

        const isDeleted = computed(() => asset.value?.status === StatusCode.Deleted);

        const retrieve = () => {
            if (accessibilityKey.value)
                root.$router.push({
                    name: 'retrieval:create',
                    params: { assetId: `${id.value}`, accessibility: accessibilityKey.value, backTo: 'assets' },
                });
        };

        const downloadProcessedSample = () =>
            download([JSON.stringify(processedSample.value, null, '\t')], 'processedSample.json');

        const refreshPage = () => {
            if (id.value) {
                fetchAsset(id.value);
                store.dispatch.notificationEngine.clearRefreshProvenanceIds();
            }
        };

        const deepFlattenToObject = (obj: any, prefix = '', seperator = '_') => {
            return Object.keys(obj).reduce((acc, k) => {
                if (typeof obj[k] === 'object' && obj[k] !== null) {
                    Object.assign(acc, deepFlattenToObject(obj[k], prefix + k + seperator));
                } else {
                    acc[prefix + k] = obj[k];
                }
                return acc;
            }, {});
        };

        const loadSample = ref<boolean>(true);

        /**
         * Computes a cropped sample based on the flattened representation of a nested sample.
         *
         * This function takes a nested sample, flattens it, and computes a cropped version
         * based on the specified field limit. If the flattened sample has fewer or equal
         * fields than the limit, the original sample is returned. Otherwise, the sample
         *
         * is cropped to include fields up to the specified limit.
         * @returns The computed cropped sample.
         */
        const croppedSample = computed(() => {
            if (!processedSample.value || !processedSample.value?.length) return processedSample.value;
            const flattenedSample = deepFlattenToObject(processedSample.value);
            const keys = Object.keys(flattenedSample);
            if (keys.length <= SAMPLE_FIELDS_LIMIT) return processedSample.value;

            // during flattening, each key is prefixed by the index in the proccessedSample array e.g, "24_Building_heatingDatetime"
            // to handle samples with a large number of fields or rows, if we reach the key at the SAMPLE_FIELDS_LIMIT position,
            // we can extract the original index from the key.
            // This allows us to truncate the sample to stay within the specified limit.
            const lastRow = parseInt(keys[SAMPLE_FIELDS_LIMIT].split('_')[0]); // e.g. lastRow is 24 for "24_Building_heatingDatetime"
            if (lastRow === 0) {
                // we can't even display a single record so don't display anything
                loadSample.value = false;
                return [];
            }
            return processedSample.value.slice(0, lastRow);
        });

        watch(
            () => asset.value,
            async () => {
                if (asset.value?.structure?.domain && asset.value.structure?.primaryConcept) {
                    const { majorVersion, uid } = asset.value.structure.domain;
                    const domain: Model = await getDomain(uid, majorVersion);

                    if (domain) {
                        loadingFlatModel.value = true;
                        try {
                            const res = await ModelsAPI.conceptNames(domain.id);
                            flatModel.value = res.data;
                            loadingFlatModel.value = false;
                        } catch {
                            loadingFlatModel.value = false;
                        }
                    }

                    loadingProcessedSample.value = true;
                    exec(AssetsAPI.getProcessedSample(asset.value.id))
                        .then((res: any) => {
                            processedSample.value = res.data;
                            loadingProcessedSample.value = false;
                        })
                        .catch(() => (loadingProcessedSample.value = false));
                }

                if (asset.value)
                    exec(AssetsAPI.getInputAssets(asset.value.id)).then((res: any) => {
                        inputAssets.value = res.data?.inputAssets;
                        rejectedItems.value = res.data?.accessControl?.rejectedItems || 0;
                    });
            },
        );

        watch(
            () => flatModel.value,
            () => loadDataStructure(),
        );

        watch(
            () => id.value,
            (newId: number | undefined) => {
                if (!R.isNil(newId)) fetchAsset(newId);
            },
            { immediate: true },
        );

        return {
            S,
            error,
            asset,
            loading,
            tabs,
            activeTab,
            dayjs,
            spatialCoverage,
            metadata,
            dataStructure,
            temporalCoverage,
            temporalCoverageDescription,
            spatialCoverageDescription,
            fromNow,
            user,
            assetStatusLabel,
            assetStatusClass,
            inputAssets,
            assetType,
            AssetType,
            isDataOwner,
            modelDisplayName,
            ModelSource,
            formatDecimals,
            customError,
            navigationPayload,
            backToAssets,
            editAsset,
            showDeleteModal,
            modalConfirmed,
            showActions,
            isModel,
            isUploadedModel,
            StatusCode,
            archive,
            restore,
            processedSample,
            AccessLevel,
            AccessLevelsOptions,
            belongsToUserOrOrganisation,
            retrieve,
            rejectedItems,
            isRetrievalDisabled,
            loadingProcessedSample,
            loadingFlatModel,
            downloadProcessedSample,
            refresh,
            refreshPage,
            confirmDelete,
            confirmationDeleteMessage,
            R,
            AccrualPeriodicityUnits,
            AccrualPeriodicityInterval,
            isDeleted,
            hiddenSpatialValuesCount,
            croppedSample,
            loadSample,
            tabIndex,
            retrievalTooltip,
            formatDateTime,
            updatedDate,
            accessibilityKey,
        };
    },
});
